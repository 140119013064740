import React, {useContext, useState} from "react";
import Cookies from "js-cookie";
const logo_ordago = 'logo-ordago.png';
const picto_ordago = 'picto-ordago.png';

// import {Button, Grid, TextField} from "@material-ui/core";
// import axios from "../../axiosConfig";


export const LogoForTools = (props) => {
  const [logoTools, setLogoTools] = React.useState(logo_ordago);
  React.useEffect(() => {
    if (Cookies.get('parent_account')) {
      let parent_account = JSON.parse(Cookies.get('parent_account'))
      setLogoTools(parent_account['logo'])
    }
  }, [logoTools])
    return (
      <>
        <img
          style={props.style}
          src={`${process.env.PUBLIC_URL}/logo/${props.folder}/${logoTools}`}
          alt="Logo"/>
      </>)
}

export const LogoSwitchAccount = (props) => {
  const {login} = useContext(props.AuthContext);
  const [wrong, setWrong] = useState(undefined);
  const [error, setError] = useState(undefined);
  const handleExportLinkCsv = (e, userid) => {
    login({username: null, password: null, userid: userid}).then(reponse =>{
      window.location.reload(false);
    }).catch(err => {
      if (err.response) {
        setWrong(err.response.data.errors)
        if (err.response.status === 500) {
          setError(true)
        }
      }
    })
  };

    return (
      <>
        {props.accountSwitch.map((data, idx) => (
          <span key={idx}>
            <img
            style={props.style}
            src={`${process.env.PUBLIC_URL}/logo/${props.folder}/logo-${data.account.name}.png`}
            onClick={e => handleExportLinkCsv(e, data.account.id)}
            />
          </span>

      ))}
      </>)
}

export const PictoForTools = (props) => {
  const [pictoTools, setPictoTools] = React.useState(picto_ordago);
  React.useEffect(() => {
    if (Cookies.get('parent_account')) {
      let parent_account = JSON.parse(Cookies.get('parent_account'))
      setPictoTools(parent_account['picto'])
    }
  }, [pictoTools])
    return (
      <>
        <img
          style={props.style}
          src={`${process.env.PUBLIC_URL}/logo/${props.folder}/${pictoTools}`}
          alt="Picto"/>
      </>)
}

