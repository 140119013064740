import { CircularProgress, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from 'react';

const useStyles = makeStyles(() => ({
  line: {
    display: "flex", justifyContent: "center",
  },
  root: {
    width: '50px',
    height: '50px',
    background: 'white',
    borderRadius: '50%',
    borderColor: '#3f51b5',
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',
    color: 'grey'
  }
}));

const Loading = (props) => {
  const { color } = props;
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (progress < 100) {
        setProgress(progress + 1);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [progress]);

  return (
    <Container maxWidth={"xs"} {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.line}>
          <CircularProgress variant="indeterminate" color={color} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Loading;

Loading.defaultProps = {
  color: "primary"
};
