import { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {authLogin, authLogout, checkIsAuthenticated} from 'services/security/auth-service'

export const AuthContext = createContext({})

export default function AuthProvider(props) {

  const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get("authenticated"))
  const [role, setRole] = useState("")
  const [accessRights, setAccessRights] = useState(Cookies.get("permissions") ? parseInt(Cookies.get("permissions")) : 1000)

  useEffect(() => {
    checkAuth().then(() => {
    })
  }, [])

  const checkAuth = () => checkIsAuthenticated()
      .then(response => {
        setIsAuthenticated(true)
        if (response.data.user.role) {
          setRole(response.data.user.role)
          setAccessRights(response.data.user.role.access_rights)
          let parent_account = response.data.parent_account
          let account_switch = response.data.account_switch
          let permissions_apps = response.data.user.permissions_apps
          let user = {first_name: response.data.user.first_name, last_name: response.data.user.last_name, email:response.data.user.email}
          localStorage.setItem("organizations", JSON.stringify(response['data']['identity']['organizations']))
          localStorage.setItem("actual_organization", JSON.stringify(response['data']['identity']['first_organization']))
          Cookies.set("account_switch", JSON.stringify(account_switch))
          Cookies.set("permissions_apps", JSON.stringify(permissions_apps))
          Cookies.set("parent_account", JSON.stringify({slug: parent_account.slug, logo:parent_account.logo, picto:parent_account.picto}))
          Cookies.set("user", JSON.stringify(user))
        } else {
          setRole("Utilisateur")
          setAccessRights(1000)
        }
        if (!Cookies.get("authenticated")) {
          if (process.env.NODE_ENV === "development") {
            Cookies.set("authenticated", true)
            if (response.data.user.role.access_rights >= 0) {
              Cookies.set("permissions", response.data.user.role.access_rights)
            } else {
              Cookies.set("permissions", 1000)
            }
          } else {
            Cookies.set("authenticated", true, {expires: 0.85})
            if (response.data.role.access_rights >= 0) {
              Cookies.set("permissions", response.data.user.role.access_rights, {expires: 0.85})
            } else {
              Cookies.set("permissions", 1000, {expires: 0.85})
            }
          }
        }
      })
      .catch(error => {
        setIsAuthenticated(false)
        Cookies.remove("authenticated")
        Cookies.remove("permissions")
        Cookies.remove("account_switch")
        Cookies.remove("permissions_apps")
        Cookies.remove("parent_account")
        Cookies.remove("user")
        throw error
      })

  const login = credentials => authLogin(credentials)
      .then(response => {
        setIsAuthenticated(true)
        setRole(response.data.role.name)
        setAccessRights(response.data.role.access_rights)
        let parent_account = response.data.parent_account
        let account_switch = response.data.account_switch
        let permissions_apps = response.data.permissions_apps
        let user = {first_name: response.data.first_name, last_name: response.data.last_name, email:response.data.email}
        localStorage.setItem("organizations", JSON.stringify(response['data']['identity']['organizations']))
        localStorage.setItem("actual_organization", JSON.stringify(response['data']['identity']['first_organization']))
        if (process.env.NODE_ENV === "development") {
          Cookies.set("authenticated", true)
          if (response.data.role.access_rights >= 0) {
            Cookies.set("permissions", response.data.role.access_rights)
            Cookies.set("account_switch", JSON.stringify(account_switch))
            Cookies.set("permissions_apps", JSON.stringify(permissions_apps))
            Cookies.set("parent_account", JSON.stringify({slug: parent_account.slug, logo:parent_account.logo, picto:parent_account.picto}))
            Cookies.set("user", JSON.stringify(user))
          } else {
            Cookies.set("permissions", 1000)
          }
        } else {
          Cookies.set("authenticated", true, {expires: 0.85})
          if (response.data.role.access_rights >= 0) {
            Cookies.set("permissions", response.data.role.access_rights, {expires: 0.85})
            Cookies.set("account_switch", JSON.stringify(account_switch))
            Cookies.set("permissions_apps", JSON.stringify(permissions_apps))
            Cookies.set("parent_account", JSON.stringify({slug: parent_account.slug, logo:parent_account.logo, picto:parent_account.picto}))
            Cookies.set("user", JSON.stringify(user))
          } else {
            Cookies.set("permissions", 1000, {expires: 0.85})
          }
        }
      })
      .catch(error => {
        setIsAuthenticated(false)
        Cookies.remove("authenticated")
        Cookies.remove("permissions")
        Cookies.remove("account_switch")
        Cookies.remove("permissions_apps")
        Cookies.remove("parent_account")
        Cookies.remove("user")
        localStorage.clear()
        throw error
      })

  const logout = () => {
    authLogout().then(() => {
      setIsAuthenticated(false)
      setRole("")
      setAccessRights(1000)
      localStorage.clear()
      Cookies.remove("authenticated")
      Cookies.remove("permissions")
      Cookies.remove("account_switch")
      Cookies.remove("permissions_apps")
      Cookies.remove("parent_account")
      Cookies.remove("user")
    })
  }

  return (
      <AuthContext.Provider value={{isAuthenticated, role, accessRights, login, logout, checkAuth}}>
        {props.children}
      </AuthContext.Provider>
  )
}
