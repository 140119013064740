import axios from 'axiosConfig';
import {sewan_search, url_sewan_change_organization, url_sewan_get_parent_site, url_sewan_get_relations} from "./vars";

//A FAIRE : REDEFINIR UN ENTRYPOINT SPECIALEMENT POUR CA !!!!//
// searchId = contient la recherche physique
// types_client = client|site|utilisateur|multi-site|
// services = mobile
export const searchSewan = (searchId, types_client, services) => {
  return axios.post(sewan_search, {
    searchId: searchId,
    types_client: types_client ? types_client : [],
    services: services ? services : []
  })
}

export const useSewanGetRelations = (entity, services, minimal_info, use_cache) =>{
  return axios.post(url_sewan_get_relations, {
    ownerId: entity,
    services: services,
    minimal_info: minimal_info,
    use_cache: use_cache
  })
}

export const functionalitySewanGetParentSite = (parent_id, type) =>{
  return  axios.post(url_sewan_get_parent_site, {
    per_id : parent_id,
    per_type: type
  })
}

export const changeOrganization = (entity) =>{
  return axios.post(url_sewan_change_organization, {
    organization_id: entity,
  })
}
